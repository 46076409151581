import Store from "./Store";
import { mapSeries } from "async";
import { route } from "preact-router";
import uuidv4 from "uuid";

import { errors } from "../constants";

const initialState = {
  reports: [],
  _report: { screenshots: [], signed: false, sending: false },

  _licenses: {},
  licenseIds: ["0b6c760d-0ace-4a82-acc3-cd0b54197854"],
  _license: {
    images: [],
    downloads: "",
    cities: [],
    description: {},
    holderName: {},
    licenseName: {},
    offeringPrice: {},
    totalDownloads: {},
    dailyTransactions: {}
  },
  images: [],
  language: "en",
  _storageOrigin: "https://storage.googleapis.com/nft-tokyo.appspot.com",
  title: "LICENSES"
};

const readAsDataURL = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const readAsBuffer = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(Buffer(reader.result));
      // resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
    // reader.readAsBinaryString(file);
  });

const putFile = (url, file) =>
  new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "PUT",
      body: await readAsBuffer(file),
      headers: { "Content-Length": file.size }
    })
      .then(res => res.json())
      .then(resolve)
      .catch(reject);
  });

const putBuffer = (url, body) =>
  new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "PUT",
      body,
      headers: { "Content-Length": body.length }
    })
      .then(res => res.json())
      .then(resolve)
      .catch(reject);
  });
const putBase64 = (url, b64) =>
  new Promise(async (resolve, reject) => {
    const body = new Buffer(b64, "base64");
    fetch(url, {
      method: "PUT",
      body,
      headers: { "Content-Length": body.length }
    })
      .then(res => res.json())
      .then(resolve)
      .catch(reject);
  });
const postJson = (url, params) =>
  new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-type": "application/json" }
    })
      .then(res => res.json())
      .then(json => {
        // @todo
        if (json.error) {
          reject(json);
        } else {
          resolve(json);
        }
      })
      .catch(reject);
  });

const checkWeb3 = () =>
  new Promise((resolve, reject) => {
    if (typeof web3 !== "undefined") {
      web3.version.getNetwork((err, netId) => {
        if (err) {
          resolve({ error: errors.CANNOT_GET_NETWORK });
        } else if (web3.eth.accounts[0]) {
          resolve({ account: web3.eth.accounts[0] });
        } else {
          resolve({ error: errors.NO_ACCOUNT });
        }
      });
    } else {
      resolve({ error: errors.NO_WEB3 });
    }
  });

export default new Store({
  state: initialState,
  commands: {
    async newReport(context) {
      context.commit("reportReset");
    },
    async fetchReports(context) {
      console.log("called");
      const address = web3
        .toChecksumAddress(web3.eth.accounts[0])
        .toLowerCase();
      const params = { address };
      postJson("/api/reports", params).then(json => {
        context.commit("reportsFetched", json);
      });
    },
    async sendReport(context, params) {
      const reportedAt = new Date();
      context.commit("reportSending", context, true);
      let { _report } = context.state;
      const { origin } = location;
      const { note } = params;
      _report.note = note;

      // console.log(_report);

      let i = 0;
      mapSeries(
        _report.screenshots,
        (screenshot, callback) => {
          i++;
          // console.log(screenshot.file);
          const ext = screenshot.file.type.split(/\//)[1];
          const fileName = `/reports/${_report.uuid}/${i}.${ext}`;
          const metadata = { contentType: screenshot.file.type };
          postJson("/api/report/url", { fileName, origin, metadata })
            .then(async json => {
              putFile(json.url, screenshot.file)
                .then(json => {
                  console.log(json);
                  callback(null, json);
                })
                .catch(err => {
                  callback(err, null);
                });
            })
            .catch(err => {
              callback(err, null);
            });
        },
        (err, results) => {
          const screenshots = results.map(result => result.name);
          const fileName = `/reports/${_report.uuid}/report.json`;
          const metadata = { contentType: "application/json, charset=utf-8" };
          postJson("/api/report/url", { fileName, origin, metadata })
            .then(json => {
              const report = {
                id: _report.uuid,
                address: _report.walletAddress,
                note,
                screenshots,
                reportedAt
              };
              const str = JSON.stringify(report, null, 2);
              putBuffer(json.url, Buffer.from(str))
                .then(json => {
                  postJson("/api/report", report)
                    .then(json => {
                      context.commit("reportSending", context, false);
                    })
                    .catch(err => {
                      context.commit("reportSending", context, false);
                    });
                })
                .catch(err => {
                  context.commit("reportSending", context, false);
                });
            })
            .catch(err => {});
        }
      );
      // postJson('/api/report', _report)
      // 	.then(json => {
      // 		console.log(json);
      // 	})
      // 	.catch(console.error);
    },
    async setReportImage(context, files) {
      mapSeries(
        files,
        (file, callback) => {
          // console.log(file);
          if (!file.type.match(/\/(png|jpg|jpeg|gif|svg)$/i)) {
            callback(null, null);
            return;
          }
          // console.log(file);
          // callback(null, { file, dataUri: await readAsDataURL(file) });
          // callback(null, { file});

          readAsDataURL(file).then(dataUri => {
            callback(null, { file, dataUri });
          });
        },
        (err, results) => {
          context.commit(
            "reportImageSet",
            results.filter(result => result != null)
          );
        }
      );
    },
    async signToReport(context, message) {
      // const message = 'Push sign button to report a copyright infringiment.';
      const signer = web3.toChecksumAddress(web3.eth.accounts[0]);

      web3.personal.sign(web3.fromUtf8(message), signer, (err, result) => {
        const signature = result;

        const params = {
          message,
          signature,
          signer
        };
        postJson("/api/verify/report", params)
          .then(json => {
            context.commit("signedToReport", json);
          })
          .catch(err => {
            context.commit("signedToReport", null);
          });
      });
    },
    async checkWeb3(context) {
      const env = await checkWeb3();
      context.commit("web3Checked", env);
    },
    setTitle(context, { licenseId }) {
      const { _licenses, language } = context.state;
      const license = _licenses[licenseId];

      if (!license) {
        return;
      }
      const title = `${license.metadata.licenseHolder[language]} - ${license.metadata.licenseName[language]}`;
      context.commit("titleSet", { title });
    },
    setLanguage(context, lang) {
      context.commit("languageSet", lang);
    },
    init(context) {
      const { licenseIds, _storageOrigin } = context.state;
      mapSeries(
        licenseIds,
        (licenseId, callback) => {
          const url = `${_storageOrigin}/licenses/${licenseId}/license.json?${new Date().toISOString()}`;
          fetch(url)
            .then(res => res.json())
            .then(license => {
              callback(null, license);
            });
        },
        (err, results) => {
          let _licenses = {};
          results.map(license => {
            _licenses[license.id] = license;
          });
          context.commit("inited", { _licenses });
        }
      );
    },
    async setData(context, params) {
      const { id } = params;
      context.commit("dataSet");
    }
  },
  mutations: {
    reportReset(state) {
      state._report = { screenshots: [] };
      route("/report");
    },
    reportsFetched(state, reports) {
      state.reports = reports;
    },
    reportSending(state, store, sending) {
      if (!sending) {
        store.command("fetchReports");
      }
      state._report.sending = sending;
    },
    reportImageSet(state, screenshots) {
      state._report.screenshots = screenshots;
    },
    signedToReport(state, json) {
      if (json) {
        state._report.walletAddress = json.address;
        state._report.uuid = uuidv4();
        state._report.signed = true;
        console.log(state);
      } else {
        state._report.signed = false;
      }
    },
    web3Checked(state, env) {
      state._report.env = env;
      if (env.error) {
        state._errorOrigin = location.href.toString();
        route(`/error/${env.error}`);
      }
    },
    titleSet(state, { title }) {
      console.log(title);
      state.title = title;
    },
    languageSet(state, lang) {
      state.language = lang;
    },
    inited(state, params) {
      const { _licenses } = params;
      state._licenses = _licenses;
    },
    dataSet(state) {}
  }
});
