import { h, Component } from 'preact';
import { Router } from 'preact-router';

import store from '../stores/MyStore';

import Header from './header';
import Footer from './footer';
import Home from '../routes/home';
import License from '../routes/license';
import Report from '../routes/report';
import Reports from '../routes/reports';
import Error from '../routes/error';
import NotFound from '../routes/404';
// import Home from 'async!../routes/home';
// import Profile from 'async!../routes/profile';

import { addLocaleData, IntlProvider } from 'react-intl';

const languages = require('../constants/languages.json');

let messages = {};
languages.map(language => {
	if (language.startsWith('zh')) {
		addLocaleData(require(`../../locale-data/zh.js`));
	}
	else {
		// addLocaleData(require(`react-intl/locale-data/${language}.js`));
		addLocaleData(require(`../../locale-data/${language}.js`));
	}
	messages[language] = require(`../../translations/${language}.json`);
});

export default class App extends Component {
	constructor(props, context) {
		super(props, context);
		this.props = props;
		this.state = store.state;
	}

	componentWillMount() {
		// start with store's initial state
		this.setState(store.state);
		// subscribe to state changes
		store.onChange(state => {
			this.setState(state, () => {});
		});
	}

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.setState({
			currentUrl: e.url
		});
	};

	render() {
		return (
			<IntlProvider
				locale={this.state.language}
				messages={messages[this.state.language]}
			>
				<div id="app">
					<Header selectedRoute={this.state.currentUrl} store={store} />
					<Router onChange={this.handleRoute}>
						<License
							path="/"
							licenseId="0b6c760d-0ace-4a82-acc3-cd0b54197854"
							store={store}
						/>
						<License path="/license/:licenseId" store={store} />
						<Report path="/report" store={store} />
						<Reports path="/reports" store={store} />
						<Error path="/error/:errorId" store={store} />
						<NotFound default />
					</Router>
					<Footer />
				</div>
			</IntlProvider>
		);
	}
}
