import { h, Component } from 'preact';
import { route } from 'preact-router';
import TopAppBar from 'preact-material-components/TopAppBar';
import Drawer from 'preact-material-components/Drawer';
import List from 'preact-material-components/List';
import Dialog from 'preact-material-components/Dialog';
import Switch from 'preact-material-components/Switch';
import Menu from 'preact-material-components/Menu';
import IconButton from 'preact-material-components/IconButton';
import 'preact-material-components/Switch/style.css';
import 'preact-material-components/Dialog/style.css';
import 'preact-material-components/Drawer/style.css';
import 'preact-material-components/List/style.css';
import 'preact-material-components/Menu/style.css';
import 'preact-material-components/MenuSurface/style.css';
// import 'preact-material-components/TopAppBar/style.css';
import '../../styles/TopAppBar/style.css';
import style from './style';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default class Header extends Component {
	constructor(props, context) {
		super(props, context);
		this.props = props;
		this.state = props.store.state;
	}

	componentWillMount() {
		// start with store's initial state
		// console.log(this.props.store.state);
		this.setState(this.props.store.state);
		// subscribe to state changes
		this.props.store.onChange(state => {
			this.setState(state, () => {});
		});
		this.props.store.command('init');
	}

	componentWillReceiveProps({ selectedRoute }, nextContext) {
		if (selectedRoute) {
			const licenseId = selectedRoute.split('/')[2];
			const { language, _licenses } = this.state;
			if (!_licenses) {
				return;
			}
			const license = _licenses[licenseId];
			if (!license) {
				return;
			}
			const { metadata } = license;
			const title = `${metadata.licenseName[language]} by ${
				metadata.holderName[language]
			}`;
			this.setState({ title });
		}
	}

	closeDrawer() {
		this.drawer.MDComponent.open = false;
	}

	openDrawer = () => (this.drawer.MDComponent.open = true);

	openSettings = () => this.dialog.MDComponent.show();

	drawerRef = drawer => (this.drawer = drawer);
	dialogRef = dialog => (this.dialog = dialog);

	linkTo = path => () => {
		route(path);
		this.closeDrawer();
	};

	pickLanguage = e => {
		const lang = e.target.getAttribute('lang');
		this.props.store.command('setLanguage', lang);
	};

	goHome = this.linkTo('/');
	goReport = this.linkTo('/report');

	goLicense = licenseId => this.linkTo(`/license/${licenseId}`);

	render(props) {
		const { title, _licenses, language } = this.state;

		const licenseItems = Object.keys(_licenses).map((licenseId, i) => {
			const license = _licenses[licenseId];
			const { licenseName } = license.metadata;
			return (
				<Drawer.DrawerItem
					onClick={this.goLicense(licenseId).bind(this)}
					selected={i == 0}
				>
					<List.ItemGraphic>mood</List.ItemGraphic>
					{licenseName[language]}
				</Drawer.DrawerItem>
			);
		});
		return (
			<div>
				<TopAppBar className="topappbar" fixed>
					<TopAppBar.Row>
						<TopAppBar.Section align-start>
							<TopAppBar.Icon menu onClick={this.openDrawer}>
								menu
							</TopAppBar.Icon>
							<TopAppBar.Title class={style.title}>{title}</TopAppBar.Title>
						</TopAppBar.Section>
						<TopAppBar.Section align-end shrink-to-fit>
							<Menu.Anchor>
								<TopAppBar.Icon
									onClick={e => {
										this.menu.MDComponent.open = true;
									}}
								>
									public
								</TopAppBar.Icon>

								<Menu
									ref={menu => {
										this.menu = menu;
									}}
								>
									<Menu.Item lang="en" onClick={this.pickLanguage.bind(this)}>
										English
									</Menu.Item>
									<Menu.Item lang="ja" onClick={this.pickLanguage.bind(this)}>
										Japanese
									</Menu.Item>
									<Menu.Item lang="ko" onClick={this.pickLanguage.bind(this)}>
										Korean
									</Menu.Item>
									<Menu.Item lang="es" onClick={this.pickLanguage.bind(this)}>
										Spanish
									</Menu.Item>
									<Menu.Item
										lang="zh-cn"
										onClick={this.pickLanguage.bind(this)}
									>
										Chinese
									</Menu.Item>
									<Menu.Item
										lang="zh-tw"
										onClick={this.pickLanguage.bind(this)}
									>
										Traditional Chinese
									</Menu.Item>
									<Menu.Item lang="ru" onClick={this.pickLanguage.bind(this)}>
										Russian
									</Menu.Item>
									<Menu.Item lang="fr" onClick={this.pickLanguage.bind(this)}>
										French
									</Menu.Item>
									<Menu.Item lang="de" onClick={this.pickLanguage.bind(this)}>
										German
									</Menu.Item>
									<Menu.Item lang="pt" onClick={this.pickLanguage.bind(this)}>
										Portuguese
									</Menu.Item>
									<Menu.Item lang="id" onClick={this.pickLanguage.bind(this)}>
										Indonesian
									</Menu.Item>
									<Menu.Item lang="th" onClick={this.pickLanguage.bind(this)}>
										Thai
									</Menu.Item>
									<Menu.Item lang="in" onClick={this.pickLanguage.bind(this)}>
										India
									</Menu.Item>
								</Menu>
							</Menu.Anchor>
						</TopAppBar.Section>
					</TopAppBar.Row>
				</TopAppBar>
				<Drawer modal ref={this.drawerRef}>
					<Drawer.DrawerContent>
						{licenseItems.length > 0 ? (
							licenseItems
						) : (
							<Drawer.DrawerItem onClick={this.goHome} selected>
								<List.ItemGraphic>home</List.ItemGraphic>
								Home
							</Drawer.DrawerItem>
						)}
						{/* <Drawer.DrawerItem onClick={this.goReport}>
							<List.ItemGraphic>report</List.ItemGraphic>
							<FormattedMessage {...messages.report} />
						</Drawer.DrawerItem> */}
					</Drawer.DrawerContent>
				</Drawer>
				<Dialog ref={this.dialogRef}>
					<Dialog.Header>Settings</Dialog.Header>
					<Dialog.Body>
						<div>
							Enable dark theme <Switch onClick={this.toggleDarkTheme} />
						</div>
					</Dialog.Body>
					<Dialog.Footer>
						<Dialog.FooterButton accept>OK</Dialog.FooterButton>
					</Dialog.Footer>
				</Dialog>
			</div>
		);
	}
}
