import { h, Component } from 'preact';
import { route } from 'preact-router';

import LayoutGrid from 'preact-material-components/LayoutGrid';
import 'preact-material-components/LayoutGrid/style.css';

import style from './style.scss';

export default class Footer extends Component {
	render(props) {
		// console.log(props.selectedRoute);
		const year = new Date().getFullYear();
		return (
			<div class={style.footer}>
				<LayoutGrid class={style.footerGrid}>
					<LayoutGrid.Inner>
						{/* <LayoutGrid.Cell
							desktopCols={4}
							tabletCols={2}
							phoneCols={2}
							class={style.buttonCell}
							style={{ textAlign: 'center', height: '100%' }}
							onClick={e => {
								route('/contact');
							}}
						>
							Contact
						</LayoutGrid.Cell> */}
						{/* <LayoutGrid.Cell
							desktopCols={3}
							tabletCols={2}
							phoneCols={2}
							class={style.buttonCell}
							style={{ textAlign: 'center', height: '100%' }}
							onClick={e => {
								route('/inquiry');
							}}
						>
							Terms & Conditions
						</LayoutGrid.Cell> */}
						<LayoutGrid.Cell
							desktopCols={6}
							tabletCols={4}
							phoneCols={2}
							class={style.buttonCell}
							style={{ textAlign: 'center', height: '100%' }}
							onClick={e => {
								location.href =
									'https://app.termly.io/document/privacy-policy/a2aacb62-d89f-4175-a939-b9a6705c98ac';
							}}
						>
							Privacy Policy
						</LayoutGrid.Cell>
						<LayoutGrid.Cell
							desktopCols={6}
							tabletCols={4}
							phoneCols={2}
							class={style.buttonCell}
							style={{ textAlign: 'center', height: '100%' }}
							onClick={e => {
								location.href = 'https://linker.plus/';
							}}
						>
							&copy; {year} Linker LLC
						</LayoutGrid.Cell>
					</LayoutGrid.Inner>
				</LayoutGrid>
			</div>
		);
	}
}
