module.exports = {
	errors: { NO_WEB3: 2, CANNOT_GET_NETWORK: 5, NO_ACCOUNT: 4, NOT_RINKEBY: 3 },
	deeplinks: {
		trust: url =>
			`https://links.trustwalletapp.com/a/key_live_lfvIpVeI9TFWxPCqwU8rZnogFqhnzs4D?&event=openURL&url=${encodeURI(
				url
			)}`,
		tokenPocket: url =>
			`https://tokenpocket.github.io/applink?dappUrl=${encodeURI(url)}`
	}
};
